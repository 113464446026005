import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Analytics from "./pages/Analytics";
import Broad from "./pages/Broad";
import BookMeeting from "./pages/BookMeeting";

import ForgotPassword from "./pages/ForgotPassword";
import IndividualPackage from "./pages/Individual-package";
import Leads from "./pages/Leads";
import Loading from "./components/Loading";
import Login from "./pages/login";
import Otp from "./pages/Otp";
import Packages from "./pages/Packages";
import Payment1 from "./pages/Payment-1";
import ResetPassword from "./pages/ResetPassword";
import Signup from "./pages/Signup";
import Welcome from "./pages/Welcome";
import Chat from "./pages/Chat";
import Pipeline from "./pages/Pipeline";
import SearchPage from "./pages/search-result";

// Enterprise pages
import EnterpriseAnalytics from "./pages/Enterprise-Analytics";
import Calender from "./pages/Calender";
import EnterpriseDashboard from "./pages/Enterprise-dashboard";
import EnterpriseSetting from "./pages/Enterprise-setting";
import Team from "./pages/Team";

// Your App.tsx file
import "react-day-picker/dist/style.css";
import "./styles/style.css";
import "./styles/chat.css";
import "./styles/calendar.css";

// redux state
import { useSelector, useDispatch } from "react-redux";
import { currentUser, getCurrentUser } from "./slices/authSlice";
import { currentListings, getListings } from "./slices/listingsSlice";
import {
  currentConversations,
  getConversations,
} from "./slices/conversationsSlice";
import { currentCalendar, getCalendar } from "./slices/calendarSlice";

export default function App() {
  const dispatch = useDispatch();
  const auth = useSelector(currentUser);
  const listings = useSelector(currentListings);
  const conversations = useSelector(currentConversations);
  const calendar = useSelector(currentCalendar);
  const userStatus = useSelector((state) => state.auth.status);
  const listingsStatus = useSelector((state) => state.listings.status);
  const conversationsStatus = useSelector(
    (state) => state.conversations.status
  );
  const calendarStatus = useSelector((state) => state.calendar.status);

  // get user when app is initiated
  useEffect(() => {
    if (userStatus === "idle") {
      dispatch(getCurrentUser());
    }
  }, [userStatus, dispatch]);

  // get listings after user app is initiated
  useEffect(() => {
    if (userStatus === "succeeded") {
      dispatch(getListings());
    }
  }, [userStatus, dispatch]);

  // get conversations after user app is initiated
  useEffect(() => {
    if (userStatus === "succeeded") {
      dispatch(getConversations());
    }
  }, [userStatus, dispatch]);

  // get calendar after user app is initiated
  useEffect(() => {
    if (userStatus === "succeeded") {
      dispatch(getCalendar());
    }
  }, [userStatus, dispatch]);

  const getContent = (component, fallback = <Login />) => {
    if (userStatus === "loading") {
      return <Loading />;
    } else if (userStatus === "succeeded" && auth.user._id) {
      return component;
    } else {
      return fallback;
    }
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={getContent(<Dashboard />, <Welcome />)} />
          <Route
            path="/dashboard"
            element={getContent(<EnterpriseDashboard />, <Welcome />)}
          />
          <Route
            path="/analytics"
            element={getContent(<EnterpriseAnalytics />, <Welcome />)}
          />
          <Route
            path="/settings"
            element={getContent(<EnterpriseSetting />, <Welcome />)}
          />
          <Route
            path="/calendar"
            element={getContent(<Calender />, <Welcome />)}
          />
          <Route path="/team" element={getContent(<Team />, <Welcome />)} />
          <Route
            path="/search-result"
            element={getContent(<SearchPage />, <Welcome />)}
          />

          <Route path="/login" element={getContent(<Dashboard />)} />
          <Route path="/otp" element={getContent(<Otp />)} />
          <Route
            path="/packages"
            element={getContent(<Packages />, <Packages />)}
          />
          <Route path="/payment-1" element={getContent(<Payment1 />)} />

          <Route
            path="/signup"
            element={getContent(<Dashboard />, <Signup />)}
          />
          <Route path="/welcome" element={getContent(<Welcome />)} />

          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
