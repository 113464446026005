import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";

const Signup = () => {
  const [businessname, setBusinessname] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    console.log(businessname, username, email, password, password2);
    if (password != password2) {
      setErrorMessage("Passwords don't match");
      return;
    } else {
      setErrorMessage(false);
    }

    try {
      const response = await axios.post(
        "api/register",
        { businessname, username, email, password },
        {
          withCredentials: true,
        }
      );
      console.log("response", response); // Display a success message
      // Redirect to another page upon successful login
      navigate(0); // Replace '/dashboard' with the desired URL
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.error("error", error.response.data.message);
        setErrorMessage(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error", error.message);
      }
    }
  };

  return (
    <div
      className="main-container"
      style={{ width: "100%", maxWidth: "100%", paddingBottom: "0" }}
    >
      <div className="flex login-page login-page--signup">
        <img src="assets/images/logo-large.svg" alt="logo large" />
        <form onSubmit={handleSubmit} autoComplete="off">
          <h2 className="form-title">Get Started</h2>
          {errorMessage && (
            <div
              variant="button"
              fontWeight="regular"
              style={{
                marginBottom: "1rem",
                color: "red",
                display: "block",
              }}
            >
              {errorMessage}
            </div>
          )}
          <div className="input-group">
            <input
              required
              type="text"
              placeholder="Business Name"
              className="input contact-icon"
              autoComplete="none"
              onChange={(e) => setBusinessname(e.target.value)}
            />
          </div>
          <div className="input-group">
            <input
              required
              type="text"
              placeholder="Username"
              className="input user-icon"
              autoComplete="none"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="input-group">
            <input
              required
              type="email"
              placeholder="Email Adress"
              className="input email-icon"
              autoComplete="none"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group">
            <input
              required
              type="password"
              placeholder="Password"
              className="input password-icon"
              autoComplete="none"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="input-group">
            <input
              required
              type="password"
              placeholder="Password"
              className="input password-icon"
              autoComplete="none"
              onChange={(e) => setPassword2(e.target.value)}
            />
          </div>
          <button type="submit" className="btn btn--full-width mt-25">
            Sign Up
          </button>
          <div className="continue-with">Or continue with</div>
          <div className="btn-group">
            <button className="login-btn">
              <img src="assets/icons/icon-google.svg" alt="google icon" />
            </button>
            <button className="login-btn">
              <img src="assets/icons/icon-apple.svg" alt="google icon" />
            </button>
            <button className="login-btn">
              <img src="assets/icons/icon-facebook.svg" alt="google icon" />
            </button>
          </div>
          <div className="new-signup">
            Already have an account?
            <Link to="/login" className="btn">
              Sign In
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
